<template>
  <v-bottom-sheet v-model="sheet" inset max-width="500" v-if="item">
    <v-card tile>
      <v-system-bar color="success"
        >Portfolio<v-spacer></v-spacer
        ><v-btn icon @click="sheet = false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >
      <v-card-text class="title pt-8 text-center">
        {{ item.title }}<br />
      </v-card-text>
      <v-card-text class="display text-center">
        <v-avatar> <PortraitImage :value="item.student" /> </v-avatar><br />
        <PersonName :value="item.student" />
      </v-card-text>

      <v-card-text>
        <Markdown :value="item.text"></Markdown>
      </v-card-text>
      <v-card-text class="pt-2 text-center">
        <v-chip class="mx-1"
          >{{ formatDate(item.creationDate) }},
          {{ formatTime(item.creationTime) }}</v-chip
        >
        <v-chip class="mx-1"><PersonName :value="item.creator" ext /></v-chip>
      </v-card-text>
      <v-divider />
      <v-list>
        <v-list-item
          @click="
            apiReport({
              resource: 'report/portfolio',
              data: {
                type: 'portfolio',
                id: item.id,
              },
              open: true,
            })
          "
        >
          <v-list-item-icon
            ><v-icon>mdi-file-document</v-icon></v-list-item-icon
          >
          <v-list-item-content
            >Portfolio öffnen (pdf)</v-list-item-content
          ></v-list-item
        >
        <v-list-item
          @click="
            apiReport({
              resource: 'report/portfolio',
              data: {
                type: 'portfolio',
                id: item.id,
              },
              openInNew: true,
            })
          "
        >
          <v-list-item-icon><v-icon>mdi-open-in-new</v-icon></v-list-item-icon>
          <v-list-item-content
            >Portfolio in neuem Tab öffnen (pdf)</v-list-item-content
          ></v-list-item
        >
        <v-list-item
          @click="
            apiReport({
              resource: 'report/portfolio',
              data: {
                type: 'portfolio',
                id: item.id,
              },
              download: true,
            })
          "
        >
          <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
          <v-list-item-content
            >Portfolio herunterladen (pdf)
          </v-list-item-content></v-list-item
        >
      </v-list>

      <v-divider />
      <v-card-actions
        ><v-btn text block @click="sheet = false">schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import { formatDate, formatTime } from "common/utils/date.js";
export default {
  components: { PersonName, PortraitImage },
  props: ["id"],
  data() {
    return {
      item: null,
      loading: false,
      sheet: true,
    };
  },
  watch: {
    sheet() {
      if (!this.sheet) {
        window.setTimeout(() => this.$router.back(), 300);
      }
    },
  },
  methods: {
    formatDate,
    formatTime,
    async fetchData() {
      this.loading = true;
      this.item = await this.apiGet({
        resource: "portfolio/portfolio",
        id: this.id,
      });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
